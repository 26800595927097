<template>
  <div>
    <v-container class="pt-0">
      <v-row>
        <v-col md="8">
          <h1 class="form-title d-flex margin-auto">
            <v-select
              :disabled="dataLoading"
              :items="statusList"
              v-model="status"
              hide-details
              item-color="cyan"
              class="pt-0 mt-0 listing-status main-listing-status-filter"
              item-text="text"
              item-value="value"
              v-on:change="getRows"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.description"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip :color="item.color" :text-color="item.textcolor">
                    <template v-if="item.value == 'all'">{{
                      item.all_users
                    }}</template>
                    <template v-else>{{ item.user_status_count }}</template>
                  </v-chip>
                </v-list-item-action>
              </template>
            </v-select>
          </h1>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getRows"
            @keydown.tab="getRows"
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getRows"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <ListingTable
        :columnCount="4"
        :dataLoading="dataLoading"
        :rowData="rowData"
      >
        <template v-slot:thead>
          <thead>
            <tr>
              <th class="simple-table-th" width="200">Name</th>
              <th class="simple-table-th" width="300">Email</th>
              <th class="simple-table-th" width="200">Contact</th>
              <th class="simple-table-th" width="200">Roles</th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody class="custom-border-bottom custom-border-top">
            <template v-if="rowData.length > 0">
              <tr
                link
                :key="index"
                v-for="(row, index) in rowData"
                v-on:click="updateDialogConfirm(row)"
              >
                <td class="simple-table-td">
                  {{ row.display_name }}
                </td>
                <td class="simple-table-td">
                  {{ row.user_email }}
                </td>
                <td class="simple-table-td">
                  {{ row.phone_number }}
                </td>
                <td class="simple-table-td">
                  <template v-if="lodash.isEmpty(row.role) === false">{{
                    row.role.name
                  }}</template>
                </td>
              </tr>
            </template>
            <tr v-else-if="!dataLoading">
              <td colspan="5" class="py-5">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no users at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </ListingTable>
      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
      ></ListingFooter>
    </v-container>
    <Dialog :commonDialog="updateDialog">
      <template v-slot:title> Update User </template>
      <template v-slot:body>
        <v-form
          ref="userUpdateForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateUserRecords"
        >
          <UsersDetail
            :usersDetail="usersDetail"
            v-on:updateUsersDetail="updateUsersInfoDetail"
          ></UsersDetail>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          v-on:click="updateUserRecords"
          color="cyan"
        >
          Update User
        </v-btn>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="updateDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import {
  QUERY,
  CLEAR_ERROR,
  PATCH,
} from "@/core/services/store/request.module";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import UsersDetail from "@/view/pages/profile/Users-Detail.vue";
import ListingMixin from "@/core/plugins/listing-mixin";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
export default {
  name: "UserTableTemplate",
  components: {
    ListingTable,
    Dialog,
    UsersDetail,
    ListingFooter,
  },
  mixins: [ListingMixin],
  data() {
    return {
      users: [],
      pageModule: "users-listing",
      routeAPI: "users",
      routeName: "profile.users",
      usersDetail: new Object(),
      dataLoading: false,
      updateDialog: false,
      filter: {
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      usersDetailData: null,
      formValid: true,
      formLoading: false,
      statusList: [],
      status: "all",
    };
  },
  methods: {
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
      }
      this.getRows();
    },
    getRows() {
      const _this = this;

      clearTimeout(_this.timeout);
      _this.rowData = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "users",
            data: { search: _this.filter.search, status: _this.status },
          })
          .then(({ data }) => {
            _this.rowData = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
    updateDialogConfirm(row) {
      this.usersDetail = row;
      this.updateDialog = true;
    },
    updateUsersInfoDetail(dataArr) {
      this.usersDetailData = dataArr;
    },
    updateUserRecords() {
      const _this = this;
      if (!_this.$refs.userUpdateForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      const formData = {
        first_name: _this.usersDetailData.first_name,
        last_name: _this.usersDetailData.last_name,
        phone_number: _this.usersDetailData.phone_number,
        password: _this.usersDetailData.password,
        confirm_password: _this.usersDetailData.confirm_password,
      };

      _this.$store
        .dispatch(PATCH, {
          url: "users/" + this.usersDetail.id,
          data: formData,
        })
        .then(() => {
          this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;
  },
  mounted() {
    this.getRows();
  },
};
</script>
